
.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    border-bottom: 3px solid #FFE900 !important;
    opacity: 1;
}

.list-group-item.active {
    border-left: 3px solid #FFE900 !important;
    opacity: 1;
    background-color: #232427 !important;
    border-top: 1px solid #23282c !important;
    border-right: 1px solid #23282c !important;
    border-bottom: 1px solid #23282c !important;
    color: #FFFFFF !important;
}

#rounds {
    .tab-pane {
        padding: 0 !important;
    }

    .list-group-item {
        padding: 0 !important;
    }
}

