#tournament-bracket {
    //width: 100% !important;
    max-width: calc(100vw - 15%) !important;
    overflow: auto hidden !important;
}

.jQBracket {
    //width: 100% !important;
    height: 100% !important;
    //overflow: auto hidden !important;
    //overflow: auto auto !important;
    
}

.bracket {
    float: left !important;
    display: flex;
}

.round{
    width: 32% !important;
}

.teamContainer > .team:nth-child(1) {
    border-radius: 0.25rem 0.25rem 0 0 !important;
}

.teamContainer > .team:nth-child(1) .score{
    border-radius: 0 0.25rem 0 0 !important;
}

.teamContainer > .team:nth-child(2) {
    border-radius: 0 0 0.25rem 0.25rem !important;
}

.teamContainer > .team:nth-child(2) .score {
    border-radius: 0 0 0.25rem 0 !important;
}
.teamContainer {
    height: 6rem !important;
    width: 12rem !important;
}

.team{
    width: 12rem !important;
    height: 3rem !important;
}

.team.win {
    background-color: #212D40 !important;
    color: white !important;
}

.team.lose {
    background-color: #1e1d1e !important;
    color: white !important;
}

.team .label {
    width: 100% !important;
    height: 100% !important;
    margin-top: 0.25rem !important;
}

.team-name{
    font-size:large;
}

.connector{
    border-radius: 0.25rem !important;
}

.matchDate {
    width: 180px;
    position: absolute;
}
.roundName{
    top: 0;
    width: 100%;
    text-align:center;
}

.team .score {
    height: 100% !important;
    background-color: transparent !important;
    align-content:center;
    margin-top: 0.5rem;
}

.team.highlightWinner {
    background-color: #da0 !important;
}

.team.highlightLoser {
    background-color: #ccc !important;
}

.bubble{
    display: none !important;
}

.team.lose .score {
    color: #999999 !important;
}

.team.win .score {
    color: #FFF !important;
}





