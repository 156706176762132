
@import 'tabs', 'cards', 'buttons', 'brackets'/*, 'select'*/;

.icon-info {
    color: #ffe900;
}

.hs-150{
    height: 150px;
    max-height: 100%;
}
.hs-200{
    height: 200px;
    max-height: 100%;
}

.hs-300{
    height: 300px;
    max-height: 100%;
}

.va-middle {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.numberCircle {
    border-radius: 50%;
    width: 52px !important;
    height: 52px !important;
    padding: 5px;
    border: 2px solid #FFE900;
    color: #FFF;
    text-align: center;
    font: 31px Arial, sans-serif;
}

.numberCircle-min {
    border-radius: 50%;
    width: 26px !important;
    height: 26px !important;
    padding: 5px;
    border: 2px solid #FFE900;
    color: #FFF;
    text-align: center;
    font: 12px Arial, sans-serif;
}

.j-button {
    border-radius: 0.25rem;
    padding: 5px;
    border: 1px solid #FFE900 !important;
    color: #FFF;
    text-align: center;
    font: 14px Arial, sans-serif;
    height: 28px !important;
    min-width: 32px;
}

.d-button > button {
    background-color: transparent !important;
    border: 1px solid #FFE900 !important;
    height: 28px !important;
    padding-top: 2px;
}

.line-height-1{
    line-height: 1em;
}

.flex-right {
    margin-left: auto;
    order: 2;
    align-self: end;
}

.flex-right > div {
    margin-left: 8px;
}

.w-72{
    width: 72px;
}



.image-upload > input {
    display: none;
}

.custom-avatar {
  vertical-align: middle;
  width: 36px !important;
  height: 36px !important;
  border-radius: 50%;
  position: relative;
}

.team-member-avatar {
    width: 36px !important;
    height: 36px !important;
    border-radius: 50em;
}

.min-avatar {
    vertical-align: middle;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
}

.img-circle {
    border-radius: 50%;
}

img.img-circle {
    background: black;
}

.jumbotron {
  background-repeat: no-repeat;
  background-position: top;
  background-color:black;
}

.image-upload {
    z-index: 300;
}

.image-upload > label {
    font-size: 18px;
    cursor: pointer;
}

.avatar-upload {
    position: relative;
    max-width: 170px;

    .avatar-edit {
        position: absolute;
        left: 12px;
        z-index: 1;
        top: 136px;
        

        input {
            display: none;

            + label {
                &::after {
                    content: "\f030";
                    font-family: 'FontAwesome';
                    color: #FFF;
                    cursor:pointer;
                    position: absolute;
                    top: 10px;
                    left: 0;
                    right: 0;
                    text-align: center;
                    margin: auto;
                    font-size: 1.33333333em;
                }
            }
        }
    }

    .avatar-preview {
        width: 170px;
        height: 170px;
        position: relative;
        border-radius: 100%;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

        > div {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.color-inherit {
    color: inherit;
}

.h-4-rem {
    height: 4rem !important;
}

.mh-500 {
    min-height: 500px;
}

.mh-400 {
    min-height: 400px;
}

.mh-350{
    min-height:350px;
}

.mh-200{
    min-height:200px;
}
.mh-250{
    min-height:250px;
}

.mt-tournament-name{
    margin-top:154px !important;
}

.mb-tournament-jumbotron{
    margin-bottom: 2.25rem;
}


.nav-link .nav-icon {
    color: #ffe900 !important;
}

.badge-yellow {
    background-color: #ffe900 !important;
    color: #1E1D1E !important;
}

.navbar-nav > li {
    &.nav-item {
        
        .nav-link{
            height: 38px;
            margin-top: 16px;
        }
        .nav-link.active {
            height: 38px;
            margin-top: 16px;
            background: no-repeat center;
            background-size: contain;
            border-bottom: 3px solid #FFE900 !important;
        }
    }
}

.navbar-nav > li.nav-item.dropdown .nav-link .img-avatar {
  margin-top: -8px;
  width:35px;
}

.nav > li {
  &.nav-item {
    .nav-link:hover {
      //background-color: #171617;
      color: rgba(#FFFFFF, 1);
    }
  }
}

.nav-tabs > li {
  &.nav-item {
    .nav-link:hover {
      color: #e4e7ea;
      text-decoration: none;
      /*ANTON commented, synced top and inner tabs menu background-color: #2f353a;*/
    }
  }
}

//Anton: same style of top and inner tabs
.nav-tabs .nav-item .nav-link {
  padding-left: 8px;
  padding-right: 8px;
  margin-left: 8px;
  margin-right: 8px;
  color: #708393;
}

.nav-tabs .nav-item .nav-link.active {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 16px;
  margin-right: 16px;
}

  /*.nav > li {
    &.nav-item {
        .nav-link.active {
            //background-color: #171617;
            color: #FFE900;
        }
    }
}*/
  .max-content {
    width: max-content !important;
  }

  .yellow {
    color: #ffe900 !important;
  }

  .white {
      color: white !important;
  }

  .Select-control {
    background-color: #515b65 !important;
    border: 1px solid #23282c !important;
  }

  .Select-value-label {
    color: #FFFFFF !important;
  }

  .Select-menu-outer > div > div:hover {
    background-color: darkgrey !important;
  }

  .Select-menu-outer > div > div {
    background-color: #515b65 !important;
    color: #FFFFFF !important;
  }

  .Select--multi .Select-value {
    color: #FFFFFF !important;
    border: 1px solid rgba(255,255,255,.24) !important;
  }

  .Select--multi .Select-value-icon {
    border-right: 1px solid rgba(255,255,255,.24) !important;
  }

  .Select--multi.is-disabled .Select-value {
    background-color: rgba(0, 0, 0, 0.1) !important;
    border: 1px solid rgba(255,255,255,.24) !important;
    color: #FFFFFF;
  }

  .Select.is-disabled > .Select-control {
    background-color: #3a4149 !important;
    border: 1px solid rgba(255,255,255,.24) !important;
    color: #FFFFFF;
  }

  .pagination {
    float: right;
  }

  .react-bs-table-pagination {
    padding: 1em 1em;
  }

  .react-bs-table-container .table.table-bordered {
    //Overriden by Anton, to remove borders from all table, if this is required - please ask
    //border-bottom: 1px solid gray !important;
    border-bottom: 0px;
  }

  .table.table-bordered td {
    border: 0px;
  }


  .react-bs-table-container .table.table-bordered th {
    background-color: $dark;
  }

  .react-bs-container-header.table-header-wrapper .table {
    margin-bottom: 0px;
    border: 0px;
  }

  .react-bs-container-header.table-header-wrapper .table thead th {
    border: 0px;
  }

  .react-bs-container-body .table-striped tbody tr:nth-of-type(odd) {
    background-color: $black;
    border: 0px;
  }

  .table-bordered th {
    vertical-align: middle !important;
  }

  .tab-content {
    border: 0px !important;
  }
  /*DatePicker*/
.react-datetime-picker__wrapper, .react-date-picker__wrapper, .react-time-picker__wrapper {
  border: none !important;

  input {
    color: #FFFFFF !important;
  }

  select {
    color: #FFFFFF !important;

    option {
      background-color: #515b65 !important;
    }
  }

  svg {
    stroke: white !important;
  }
}

.react-datepicker-popper {
  z-index: 2000 !important;
}

.react-datetime-picker--disabled, .react-date-picker--disabled, .react-time-picker--disabled {
  background-color: #3a4149 !important;
  color: #FFFFFF !important;
}
  /*DatePicker*/
  .row.zeroPadding {
    margin-left: -30px;
    margin-right: -30px;
    //margin-top: -14px;
    margin-bottom: 0px;
  }

  .nav.nav-tabs {
    margin-bottom: 1px;
  }

  .spinner-sm {
    width: 16px;
    height: 16px;
    margin: 0.25rem;

    .sk-circle {
      width: 20px;
      height: 20px;
      margin: 0rem;
    }
  }

  .btn-brand i {
    line-height: 2.2rem !important;
  }

  @-webkit-keyframes autofill {
    to {
      color: #FFFFFF;
      background: #515b65;
    }
  }

  input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ffe900 !important;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    background-color: #ffe900 !important;
  }

  .steam-icon {
    font-size: 20px;
    color: #FFF;
  }

  .clicable-icon {
    cursor: pointer;
  }

  .clicable-icon:hover {
    filter: brightness(80%);
  }

  .app-body {
    overflow-y: hidden !important;
  }

  .card-header .nav-tabs .nav-link.active {
    color: #e4e7ea !important;
    background: #2f353a !important;
  }

  .card-header .nav-tabs .nav-link {
    color: #e4e7ea !important;
    padding: 0.25em 1em 0.25em 1em !important;
  }

  .display-4 {
    font-size: 4rem !important;
  }

  @media (max-height: 768px) {
    .jumbotron {
      max-height: 250px !important;
    }

    .mt-tournament-name {
      margin-top: 10px !important;
    }

    .mb-tournament-jumbotron {
      margin-bottom: 2.25rem;
    }

    .mh-350 {
      min-height: 150px !important;
    }
  }

  @media (max-height: 835px) {
    .jumbotron {
      max-height: 300px !important;
    }

    .mt-tournament-name {
      margin-top: 10px !important;
    }

    .mb-tournament-jumbotron {
      margin-bottom: 2.25rem;
    }

    .mh-350 {
      min-height: 230px !important;
    }

    
  }

  @media (min-width: 576px) {
    .jumbotron {
      padding: 2rem 2rem !important;
    }

    
  }
@media (max-width: 430px) {
  .fix-dropdown-menu {
    margin-top: 3rem !important;
  }
}

  .linear-gradient {
    background-color: #9dc5c3;
    background-image: linear-gradient(315deg, #9dc5c3 0%, #5e5c5c 74%);
    height: 200px;
    opacity: 1;
  }

  td {
    vertical-align: middle !important;
  }

  .img-avatar-small {
    width: 32px !important;
    height: 32px !important;
  }

  .mt-tournament-name > h1 {
    font-size: 2.5vw !important;
  }

  .ch-locker > .modal-content {
    //background: url('../../assets/img/dota2.jpg'), linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 2.6));
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 2.6)), url('../../assets/img/dota2.jpg') no-repeat;
    //background-size: cover;
    //background-repeat: no-repeat;
  }

  .modal-backdrop.show {
    opacity: 0.8 !important;
  }


  .spinner-r1 {
    background: url('../../assets/img/loading.gif');
    background-size: cover;
    width: 128px;
    height: 128px;
    margin: $spinkit-spinner-margin;
    background-color: $spinkit-spinner-color;
  }

  .table-bordered td:active, .table-bordered td:focus {
    outline-style: none;
  }

  .table-hover tbody tr:hover, .react-bs-container-body .table-striped tbody tr:hover {
    color: #e4e7ea;
    background-color: rgba(0, 0, 0, 0.075);
  }


  .card-header {
    border-bottom: 0px;
  }

  .flex-basis-45 {
    flex-basis: 45% !important;
  }

  .flex-basis-10 {
    flex-basis: 10% !important;
  }

  .ex-link:after {
    pointer-events: auto;
    font: normal normal normal 14px/1 FontAwesome;
    content: "\f08e";
    font-weight: 900;
    margin-left: 10px;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }

  .w-600 {
    width: 600px !important;
  }

  .w-60 {
    width: 60% !important;
  }

  @media (max-width: 500px) {
    .card {
      margin-bottom: 0px !important;
    }

    .card-body {
      padding-bottom: 0px !important;
    }

    #btn-edit {
      margin-right: 0px !important;
    }

    #btns-tournament {
      text-align: right;
    }
    .rbc-calendar > span {
        flex-direction: column;
    }
    .rbc-calendar > span > .rbc-toolbar-label {
      margin: auto;
    }
  }

  #loader {
    .modal-content {
      background-color: transparent !important;
      border: none !important;
      border-radius: 0 !important;
    }

    .loader-img {
      border-radius: 50em;
      max-width: 100%;
    }
  }

  .lobby-chat {
    max-height: 26vmax;
    overflow-y: auto !important;
  }

  .lobby-gamers {
    max-height: 26vmax;
    overflow-y: auto !important;
  }

  .list-group-item.onlyRow {
    padding: 0rem 1.25rem !important;
  }

  .col-min {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /***chat**/
  .rce-citem {
    background-color: #1E1D1E !important;
    user-select: text !important;
  }

  .chat-list {
    background-color: #1E1D1E;
    min-height: 200px;
  }

  .rce-avatar {
    width: 36px !important;
    height: 36px !important;
  }

  .rce-avatar-container.large {
    width: 36px !important;
    height: 36px !important;
  }

  .rce-citem-avatar {
    align-items: start !important;
  }

  .rce-citem-body {
    border-bottom: 1px solid rgba(0,200,100,.05) !important;
    margin-bottom: 10px;
  }

  .rce-citem-body--top-time {
    color: #e4e7ea !important;
  }

  .rce-citem {
    height: 100% !important
  }

  .rce-citem-body--top {
    color: #20a8d8 !important;
  }

  .rce-citem-body--bottom-title {
    color: white !important;
    white-space: pre-wrap !important;
  }

  .rce-container-input {
    background-color: #515b65 !important;
  }

  .rce-input-textarea {
    background-color: #515b65 !important;
    color: #e4e7ea !important;
    overflow: hidden !important;
  }

  .rce-citem-body--bottom {
    margin-top: 0 !important;
  }

  .sticky-alert {
    //position: fixed !important;
    z-index: 1000 !important;
    //right: 20px;
    //left: 20px;
    max-width: inherit;
    width: inherit;
    //width: calc(100% - 232px);
  }

  .top-45 {
    top: 35px !important;
  }

  .w-row-delimiter {
    width: 101.5%;
  }

  .t-name-header {
    margin-left: 60px;
  }

  .game-alert {
    //margin-left: -20px;
    margin-top: -14px;
  }

  .command-side-1 {
    color: white;
    background-color: black;
  }

  .command-side-2 {
    color: black;
    background-color: white;
  }

  .winner-badge {
    position: absolute;
    right: 18px;
    top: 48px;
    background: gold;
    text-align: center;
    border-radius: 30px 30px 30px 30px;
    color: white;
    padding: 5px 10px;
    font-size: 5px;
    z-index: 100;
  }

  .sidebar {
    .btn-primary {
      .nav-link {
        color: black;
      }
    }
  }

  .mh-results {
    max-height: 200px !important;
  }

  .rules-nav {
    width: 220px !important;
    min-width: 220px !important;
    position: sticky;
    border-right: solid 1px grey;
  }

  .container-fluid {
    &.min {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }

  .main {
    &.min {
      margin-top: 0 !important;
    }
  }

  .invite-message {
    font-size: x-large;
  }

  .react-tel-input {
    border: none !important;
    //border-radius: 0.5rem !important;
    .form-control {
      background-color: #515b65 !important;
      color: #e4e7ea !important;
      width: 100% !important;
      border: 1px solid #23282c !important;
    }

    .flag-dropdown {
      background-color: #515b65 !important;
      border: 1px solid #23282c !important;
    }

    .flag-dropdown.open, .flag-dropdown.open .selected-flag {
      background: #515b65 !important;
      border: 1px solid #23282c;
    }

    .country-list .country:hover {
      background-color: #515b65 !important;
    }

    .country-list {
      background-color: #515b65 !important;
    }

    .country-list .country.highlight {
      background-color: #3a4149 !important;
    }

    .country-list .country .dial-code {
      color: #e4e7ea !important;
    }
  }

  .rbc-time-slot {
    color: black !important;
  }

  .modal-base {
    max-width: 90% !important;
  }

  .active-filter {
    text-decoration-line: underline;
  }

  .schedule-time {
    width: 70px;
    border: 1px solid #c8ced3;
    border-radius: 0.25rem;
  }

  .checked-time {
    background-color: #515b65 !important;
    border: 1px solid #23282c !important;
    color: black;
  }

  .checked-queue-time {
    background-color: white !important;
    border: 1px solid white !important;
    color: black;
  }

  .main .container-fluid {
    padding-top: 10px !important;
  }

  .client-form {
    border: solid 1px grey;
  }

  .no-hover {
    color: #20a8d8 !important;
    text-decoration: none !important;
  }

  .selected-company {
    background-color: #232427;
    font-weight: bold;
  }

  //.react-datepicker__navigation {
  //  padding: 0 !important;
  //  border: 0.45rem solid transparent !important;
  //}
  .rbc-toolbar .react-datepicker__navigation {
    padding: 0 !important;
    border: 0.45rem solid transparent !important;
  }

  .react-datepicker__tab-loop {
    display: inline-block;
  }

  .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle{
    left: 136px !important;
  }

  .rbc-header {
    white-space: normal !important;
  }

  .react-bs-table-tool-bar > div.row {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    padding-right: 0px;
  }
  .react-bs-table-tool-bar > div.row > div {
    padding-left: 0px;
    padding-right: 0px;
  }
  .react-bs-table-pagination > div.row > div {
    width: 50%;
    padding-left: 0px;
    padding-right: 0px;
  }

  .w-98 {
    width: 98% !important;
  }

  .common-grid {
    min-width: 98% !important;
  }

  .react-bs-table {
    overflow-x: auto !important;
  }

  .preline {
    white-space: pre-line;
  }

  .tag-green {
    background-color: forestgreen !important;
    color: white !important;
    padding: 0.5rem !important;
  }

  .tag-blue {
    background-color: #17a2b8 !important;
    color: white !important;
    padding: 0.5rem !important;
  }

  .tag-yellow {
    background-color: #ffe900 !important;
    color: black !important;
    padding: 0.5rem !important;
  }

  .tag-red {
    background-color: red !important;
    color: white !important;
    padding: 0.5rem !important;
  }

  .rbc-event-content {
    white-space: normal !important;
    height: inherit !important;
  }