// Here you can add other styles

@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url(./../fonts/Montserrat.woff) format('woff');
    // https://github.com/JulietaUla/Montserrat/blob/master/fonts/webfonts/Montserrat-ExtraLight.woff
}

.app {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.inform-block-content {
    display: flex !important;
    align-items: baseline !important;
    justify-content: space-between;
    overflow: hidden;
}

.inform-block-content .inform-block-content-message {
}

.inform-block-content .inform-block-content-tournament {
}

.inform-block-content .inform-block-content-message p#timerEnd {
    margin-bottom: 0;
    font-weight: 800;
}

ul.nav-tabs li.nav-item a.nav-link {
    text-transform: uppercase
}

.tournament-rules {
    height: 200px;
    background-color: #232427;
    overflow-x: hidden;
    overflow-y: auto;
}

.error-page {
    font-family: 'Montserrat';
    text-align: center !important;
}

.error-page .btn {
    font-weight: 900;
}

.error-page .message {
    font-size: 8em;
}

.steam-friend i.fa-steam-square
{
    font-size: 1.33em;
}

.list-group{
    cursor: pointer;
}

.lh1_5 {
    line-height: 1.5;
}

.alert-shown {
    opacity: 1;
    transition: all 250ms linear;
}

.alert-hidden {
    opacity: 0;
    transition: all 500ms linear;
}

.Select-menu-outer{
    z-index:999;
}

.overflow-x-auto {
    overflow-x: auto !important;
}

.overflow-x-hidden {
    overflow-x: hidden !important;
}

.overflow-y-auto {
    overflow-y: auto !important;
}

.overflow-y-hidden {
    overflow-y: hidden !important;
}

.app-body{
    overflow-y: auto !important;
}

.card-header {
    line-height: 34px;
}

.marker {
    width: 7px;
    height: 7px;
    border-radius: 100%;
}

.separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 0.25em;
    margin-top: 0.25em;
}

.separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #73818f;
}

.separator::before {
    margin-right: .25em;
}

.separator::after {
    margin-left: .25em;
}

.img-sign-up {
    width: 1.75em;
    height: 1.75em;
}

.main {
  margin-top:24px;
}

.hidden {
  display:none !important;
}

.cursor-pointer {
    cursor: pointer;
}

.vh-75{
    max-height: 75vh !important;
}

.h25px {
    height: 25px;
    margin: 0 2px 0 0;
    overflow: hidden;
}

.padding-zd7-rem {
    padding: 0.7rem !important;
}

.border-none {
    border: 0px !important;
}

.bage-gray {
    background-color: #73818f !important;
}

/* Social Networks Starts 
    .fa-twitch {
        color: #7856b2 !important;
    }

    .fa-facebook-official {
        color: #3b5999 !important;
    }

    .fa-twitter {
        color: #55acee !important;
    }

    .fa-youtube-square {
        color: #ef444a !important;
    }

    .fa-vk {
        color: #4b74a5 !important;
    }
    /* Social Networks Ends */
    .error-message {
        color: #F86C6B !important;
    }

    .delimiter {
        height: 1px;
        background: var(--gray);
    }

    .white {
        color: white;
    }

    .victory {
        color: #64be56;
    }

    .defeat {
        color: #c43855;
    }
    /* Online Status */
    .lastSeenHolder {
    }

    .onlineStatusHolder {
        position: absolute;
        margin-left: - 2px;
        margin-top: - 2px;
    }

    .onlineStatus {
        width: 10px;
        height: 10px;
        border-radius: 100%;
    }

    .onlineStatus.Active {
        background-color: #64be56;
    }

    .onlineStatus.Away {
        display: none;
        /*background-color: #bebb56;*/
    }
    /* Online Status ends */
    /* Brackets */
    div.jQBracket .team {
        background-color: #525252;
    }
    /* Brackets Ends */
    /* Challenges */
    .background-transparent {
        background: transparent !important;
    }

    .background-dark {
        background: #1e1d1e !important;
    }

    .border-bottom-gray {
        border-bottom: 1px solid #23282c !important;
    }

    .min-height-75px {
        min-height: 75px;
    }

    .top-0 {
        top: 0px;
    }

    .right-0 {
        right: 0px;
    }

    .gameType {
        height: 40px;
        top: 50%;
        margin-top: -20px;
    }

    .selectedSpan {
        border-bottom: 1px solid;
    }

    .selectedSpan > span {
        border-bottom: 2px solid #ffe900;
    }

    .circle {
        width: 40px;
        height: 40px;
        background: gray;
        border-radius: 100%;
    }

    .custom-radio {
    }

    .custom-radio .radio-circle-wrap {
        width: 12px;
        height: 12px;
        border: 1px solid #ffe900;
        border-radius: 100%;
    }

    .custom-radio .radio-circle-wrap .radio-circle {
        width: 6px;
        height: 6px;
        background: #ffe900;
        border-radius: 100%;
    }

    .custom-radio .radio-label {
    }
    /* width */
    .slimScrollbar::-webkit-scrollbar {
        width: 4px;
    }
    /* Track */
    .slimScrollbar::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
    }
    /* Handle */
    .slimScrollbar::-webkit-scrollbar-thumb {
        background: #e3e3e3;
        border-radius: 10px;
    }
    /* Handle on hover */
    .slimScrollbar::-webkit-scrollbar-thumb:hover {
        background: white;
    }
    /* Challenges ends */
    /* Ion slider */
    .irs--modern {
        width: 100%;
    }

    .irs--modern .irs-bar {
        background: #ffe900 !important;
    }

    .irs--modern .irs-from, .irs--modern .irs-to, .irs--modern .irs-single {
        background: #ffe900 !important;
    }

    .custom-radio.d-flex {
        align-items: center;
    }
    /* Ion slider Ends */
    /* Animation */
    .transition {
        transition: 0.5s ease-in-out;
        overflow: hidden;
        opacity: 1;
        width: 100%;
        height: auto;
    }

    .transition.animatedHide {
        opacity: 0 !important;
        width: 0% !important;
        height: 0px !important;
        padding: 0px !important;
        margin: 0px !important;
    }
    /* Animation Ends */
    
    .number-in-circle {
        padding-left: 0.25rem !important;
        padding-right: 0.25rem !important;
        border-radius: 50%;
        border-style: solid,;
        border-width: 2px;
        border-color: yellow;
    }

.dynamic-flex-direction {
    display: flex;
}
@media only screen and (max-width: 768px) {
    .dynamic-flex-direction {
        display: flex;
        flex-direction: column;
    }
}

.rbc-toolbar button {
  color: white !important;
}
