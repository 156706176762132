// If you want to override variables do it here
@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
//@import "~@coreui/coreui-pro/scss/coreui.scss";

// Import styles with dark layout
// If you want to use dark layout uncomment next line
@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui-pro/scss/_dropdown-menu-right.scss";

// If you want to add something do it here
@import "custom";

// ie fixes
@import "ie-fix";

// temp fixes
@import "fixes";

@import '~spinkit/scss/spinkit.scss';

$background: rgba(#1E1D1E, 1) !global;
$black: rgba(#232427, 1) !global;
$dark: rgba(#1E1D1E, 1) !global;
// Spinkit
$body-bg: $black;//rgba(#1E1D1E, 1);;
$footer-bg: $black;
$body-color: lighten(#FFFFFF, 15%) !global;
$spinkit-spinner-color: $body-color;
$aside-menu-bg: $black;
$navbar-bg: $dark;
$navbar-border: ( bottom: ( size: 0px, style: solid, color: $dark ) );
$breadcrumb-bg: $black;
$sidebar-bg: $dark;
$sidebar-minimizer-bg:$dark;
//$sidebar-borders: ( right: ( size: 1px, style: solid, color: $border-color ) ) !default;
$tab-content-bg: $black;
$nav-tabs-link-active-bg: $black;

$navbar-color: rgba(#708393, 1) !global;
$navbar-hover-color: rgba(#FFFFFF, 1) !global;
$navbar-active-color: rgba(#FFFFFF, 1) !global;
$navbar-disabled-color: rgba(#128128, 1) !global;

$card-bg: $black;
$card-cap-bg: $black;
$list-group-action-active-bg: $black;
$list-group-bg: $black;

$jumbotron-bg:$dark;
$yellow: #ffc990 !global;
//$primary: #ffc990 !global;
$sidebar-nav-link-hover-bg: $black !global;// #171617 !global;
$nav-tabs-link-active-bg: $black !global;// #171617 !global;
$sidebar-nav-link-active-bg: $black !global;// #171617 !global;
//$list-group-border-color: $border-color !default;
//$list-group-hover-bg: $background;
//$list-group-disabled-color: $gray-600 !default;
//$list-group-action-color: $gray-200 !default;
//$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: $background;
$nav-tabs-border-width: 0;
$nav-tabs-link-active-color: rgba(#FFFFFF, 1) !global;
$nav-tabs-border-color:gray;
$nav-tabs-border-width:1px;
$aside-menu-bg:$dark;

@import './components/_global.scss';
