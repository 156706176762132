.btn-primary, .btn-info:hover {
    color: #1E1D1E !important;
    background-color: #ffe900 !important;
    border-color: #ffe900 !important;
}

.btn-info, .btn-primary:hover {
    color: #FFF !important;
    background-color: #232427 !important;
    border-color: #ffe900 !important;
}

.btn-social-actions {
    margin: 0.25rem 0.25rem 0.25rem 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    direction: rtl;

    button {
        margin: 0.25rem;
        display: block;
    }
}
.btn-steam {
    background: linear-gradient(#144376, #51aee1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.btn-steam:hover {
    background: linear-gradient(#001945, #007aaa);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.timeline-block {
    position: relative;
    height: 130px;
}

.line {
    position: absolute;
    top: 50px;
    border-bottom: solid 2px #20a8d8;
}

.l-reg {
    position: absolute;
    left: 5.5%;
    top: 50px;
    width: 20%;
    border-bottom: solid 2px #20a8d8;
    border-radius: 25%;

    &-f {
        @extend .l-reg;
        left: 5.5%;
        width: 33%;
    }
}

.l-reg-end {
    position: absolute;
    left: 25%;
    top: 50px;
    width: 11%;
    border-bottom: solid 2px #20a8d8;
    border-radius: 25%;

    &-f {
        @extend .l-reg-end;
        left: 39%;
        width: 20%;
    }
}
.l-game {
    position: absolute;
    left: 36%;
    top: 50px;
    width: 31%;
    border-bottom: solid 2px #20a8d8;
    border-radius: 25%;

    &-f {
        @extend .l-game;
        left: 59%;
        width: 34%;
    }
}

.l-start {
    left: 0%;
    width: 4%;
    border-bottom: dashed 2px #20a8d8;

    &-f {
        left: 0%;
        width: 4%;
        border-bottom: dashed 2px #20a8d8;
    }
}

.l-finish {
    position: absolute;
    left: 68%;
    top: 50px;
    width: 25%;
    border-bottom: solid 2px #20a8d8;
    border-radius: 25%;
}
.l-end {
    left: 94%;
    width: 4%;
    border-bottom: dashed 2px #20a8d8;

    &-f {
        left: 94%;
        width: 4%;
        border-bottom: dashed 2px #20a8d8;
    }
}

.point-timeline {
    position: absolute;
    top: 46px;
    background-color: white; 
    border-radius: 50%; 
    border-color: black;
    width: 10px;
    height: 10px;
    z-index:2;
}

.point-tooltip {
    position: absolute;
    top: 46px;
}

.point-timeline-t {
    background-color: white;
    border-radius: 50%;
    border-color: black;
    width: 10px;
    height: 10px;
    z-index: 2;
}

.p-start {
    left: 4.5%;
    &-f { 
        left: 4.5%; 
    }
}

.p-reg {
    left: 25%;
        &-f {
        left: 38.5%;
    }
}

.p-game {
    left: 36%;

    &-f {
        left: 59%;
    }
}

.p-checkEnd {
    left: 63%;

    &-f {
        left: 4.5%;
    }
}

.p-finish {
    left: 67%;
}

.timeline {
    position: relative;
}

.btn-reg {
    position: absolute;
    width: 20%;
    left: 5%;
    border-radius: 0.25rem 0 0 0.25rem !important;

    &-f {
        @extend .btn-reg;
        left: 5%;
        width: 33.5%;
    }
}
.btn-checkIn {
    position: absolute;
    width: 10%;
    left: 26%;
    border-radius: 0 0.25rem 0.25rem 0 !important;

    &-f {
        @extend .btn-checkIn;
        width: 20%;
        left: 39%;
    }
}
.btn-game {
    position: absolute;
    width: 30%;
    left: 37%;

    &-f {
        @extend .btn-game;
        width: 33%;
        left: 59.5%;
    }
}
.btn-final {
    position: absolute;
    width: 25%;
    left: 68%;
}

.e-start-reg{

}

.e-start {
    top: 24px;
    position: absolute;
    left: -110%;
    width: 45px;

    &-f {
        @extend .e-start;
    }
}

.e-reg-end {
    top: 24px;
    position: absolute;
    left: -110%;
    width: 45px;

    &-f {
        @extend .e-reg-end;
    }
}

.e-checkEnd {
    top: 24px;
    position: absolute;
    left: -110%;
    width: 45px;

    &-f {
        @extend .e-checkEnd;
    }
}

.e-finish {
    top: 24px;
    position: absolute;
    left: -110%;
    width: 45px;
}


.prize-size{
    font-size:18px;
}
.gold{
    color: gold !important;
}
.silver {
    color: silver !important;
}
.bronze {
    color: saddlebrown !important;
}

.active-disabled {
    color: #1E1D1E !important;
    background-color: #ffe900 !important;
    opacity: 1 !important;
}

.div-link {
    color: #20a8d8 !important;
    text-decoration: none !important;
    background-color: transparent !important;

    :focus {
        outline: -webkit-focus-ring-color auto 1px !important;
    }

    &:hover {
        color: #167495 !important;
        text-decoration: underline !important;
    }
}