.switch-warning .switch-input:checked + .switch-slider {
    background-color: #ffe900 !important;
    border-color: #C9B805 !important;
}

.badge-success {
    background-color: #ffe900 !important;
    color: #1E1D1E !important;
}

.badge-danger {
    background-color: #FF2A20 !important;
}


.card.border-primary {
    border-color: #ffe900 !important;
    border-left-width:1px !important;
    border-left-style:solid !important;
}

.card.left-border-primary {
    border-left-color: #ffe900 !important;
    border-left-width:1px !important;
    border-left-style:solid !important;
}

.card.border-danger {
    border-color: #FF2A20 !important;
}

.card.border-success {
    border-color: #06EF6F !important;
}

.card.card-accent-primary {
    border-top-color: #ffe900 !important;
    border-top-width: 2px;
}

.card.card-accent-danger {
    border-top-color: #FF2A20 !important;
    border-top-width: 2px;
}


.card.card-accent-success {
    border-top-color: #06EF6F !important;
    border-top-width: 2px;
}

.card.bg-primary {
    background-color: #ffe900 !important;
    border: 2px solid #C9B805 !important;
    color: #1E1D1E !important;
}

.card.bg-primary .card-header {
    background-color: #ffe900 !important;
    border-bottom: 1px solid #C9B805 !important;
    color: #1E1D1E !important;
}

.card.bg-info {
    background-color: #525252 !important;
    border: 2px solid rgba(47, 53, 58, 0.3) !important;
}
.card.bg-info .card-header {
    background-color: #525252 !important;
    border-bottom: 1px solid rgba(47, 53, 58, 0.3) !important;
}

.card.bg-base {
    border: 2px solid rgba(47, 53, 58, 0.3) !important;
}

.card.bg-base-light {
    background-color: rgba(47, 53, 58, 0.6) !important;
    border: 2px solid rgba(47, 53, 58, 0.5) !important;
}

.card-header {
    font-size: 1.3125rem !important;
    font-weight: 500 !important;
}

.card-header.standard {
    font-size: 0.875rem !important;
}

.card-header-view {
    font-size: 0.875rem !important;
    cursor: pointer;
}

.card-min {
    margin-bottom: 0 !important;
}

.card-body-min {
    padding: 0.5rem 0rem !important;
}

.card-header-min {
    padding: 0.5rem 0rem !important;
}

.alert-min {
    margin-bottom: 0 !important;
}

$switch-height: 21px !global;
$switch-width: 32px !global;
